<template>
  <div>
    <ca-header
      heading="Werkstätten"
      :column-layout="true"
    >
      <template #buttonSpace>
        <router-link
          v-if="me.role === 10"
          class="btn btn-primary"
          :to="{name: 'WorkshopCreate' }"
        >
          Neue Werkstatt hinzufügen
        </router-link>
      </template>
    </ca-header>
    <div class="container-fluid px-4 pt-4">
      <data-table
        :fields="fields"
        service="workshops"
        :base-query="{}"
        @row-clicked="(item) => $router.push({name: 'WorkshopDetail', params: {workshopId: item._id}})"
      />
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import DataTable from '@/components/DataTable/DataTable.vue'
export default {
  name: 'WorkshopList',
  components: {
    DataTable,
    CaHeader
  },
  computed: {
    me () {
      return this.$store.getters['auth/user'].user
    },
    fields () {
      const fields = [
        {
          label: 'Partner Nr.',
          key: 'PARTNERNR',
          filter: {
            type: 'SEARCH_IN_POPULATE',
            serviceKey: 'PARTNERNR',
            service: 'workshops',
            targetKey: 'PARTNERNR'
          }
        },
        {
          label: 'Name',
          key: 'address.NAME',
          formatter: (name) => { return name.VOR ? `${name.VOR} ${name.NAM}` : `${name.NAM}` },
          filter: {
            type: 'SEARCH_IN_POPULATE',
            serviceKey: 'NAM',
            service: 'workshops',
            targetKey: 'NAM'
          }
        },
        {
          label: 'Straße',
          key: 'address.STR'
        },
        {
          label: 'Postleitzahl',
          key: 'address.PLZ'
        },
        {
          label: 'Stadt / Ort',
          key: 'address.ORT'
        },
        {
          label: 'Land',
          key: 'address.NAT.name'
        }
      ]
      return fields
    }
  }
}
</script>
